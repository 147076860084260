<!-- 自提详情 -->
<template>
  <div>
    <back />
    <div class="det" v-loading="loading">
      <!-- 基本信息 -->
      <div class="detail">
        <h4 class="title">详情信息：</h4>
        <el-row class="row">
          <el-col :span="5">
            <div class="col order-code">订单编号：{{ basicInfo.orderCode }}</div>
            <div class="col">支付状态： <span :style="{ color: basicInfo.payStatus == '待支付' ? 'red' : '' }">
                {{ basicInfo.payStatus }}</span>
            </div>
            <div class="col">下单时间：{{ basicInfo.orderTime }}</div>

          </el-col>
          <el-col :span="5">
            <div class="col">订单类型：自提</div>
            <div class="col">订单金额：{{ Number(basicInfo.orderAmount).toFixed(2) }}元</div>
            <div class="col" v-if="basicInfo.payStatus == '已支付'">付款时间：{{ basicInfo.paymentTime }}</div>
            <div class="col" v-if="basicInfo.orderStatus == -1 && basicInfo.payStatus == '待支付'">取消时间：{{
      basicInfo.cancelTime }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">店铺名称：{{ basicInfo.shopName }}</div>
            <div class="col">打包费用：{{ Number(basicInfo.packAmount).toFixed(2) }}元</div>
            <div class="col" v-if="basicInfo.orderStatus == -1 && basicInfo.payStatus == '已支付'">取消时间：{{
      basicInfo.cancelTime }}</div>
            <div class="col" v-if="basicInfo.deliveryStatus == 4">完成时间：{{ basicInfo.completionTime }}</div>

          </el-col>
          <el-col :span="5">
            <div class="col">订单状态：
              <span :style="{ color: basicInfo.payStatus == '待支付' ? 'red' : '' }">
                {{ getStatusText(basicInfo.orderStatus) }}
              </span>
            </div>
            <div class="col">优惠金额：{{ Number(basicInfo.discountAmount).toFixed(2) }}元</div>
            <div class="col" v-if="basicInfo.orderStatus == 8 && basicInfo.payStatus == '已支付'">
              退款时间：{{ basicInfo.refundTime }}
            </div>
          </el-col>
          <el-col :span="4">
            <div class="col">订单子状态：
              <span :style="{ color: basicInfo.deliveryStatus == 1 ? 'red' : '' }">
                {{ getOrderFormStatusText(basicInfo.deliveryStatus) }}
              </span>
            </div>
            <div class="col">支付金额：{{ Number(basicInfo.payAmount).toFixed(2) }}元</div>

          </el-col>
        </el-row>
        <el-row class="row">
          <el-col>
            <div class="col">预计到店：{{ basicInfo.shopEstimatedTime }}</div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col>
            <div class="col">备注：{{ basicInfo.buyerNotes || '无备注信息' }}</div>
          </el-col>
        </el-row>
      </div>
      <!-- 用户信息 -->
      <div class="detail">
        <h4 class="title">用户信息：</h4>
        <el-row class="row">
          <el-col :span="4">
            <div class="col">用户编号：{{ basicInfo.userCode }}</div>
          </el-col>
          <el-col :span="4">
            <div class="col">昵称：{{ basicInfo.userName }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">预留号码：{{ basicInfo.phoneNumber }}</div>
          </el-col>
        </el-row>
      </div>
      <!-- 优惠券信息 -->
      <div class="detail">
        <h4 class="title">优惠券信息：</h4>
        <el-row class="row">
          <el-col :span="4">
            <div class="col">优惠券名称：{{ basicInfo.couponInfoVo?.couponName }}</div>
          </el-col>
          <el-col :span="4">
            <!-- 类型：1满减券；2折扣；3代金券（固定金额） -->
            <div class="col">优惠券类型：{{ getCouponInfo(basicInfo.couponInfoVo?.couponType) }}</div>
          </el-col>
          <el-col :span="4">
            <div class="col">减免金额：{{ Number(basicInfo.discountAmount).toFixed(2) }}元</div>
          </el-col>
        </el-row>
      </div>
      <!-- 商品信息 -->
      <div class="detail">
        <h4 class="title">商品信息：</h4>
        <!-- 表格 -->
        <div class="globle_table tableBox">
          <el-table :data="tableData">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                <!-- {{ scope.$index + 1 + (currentPage - 1) * pageSize }}  -->
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="图片" show-overflow-tooltip min-width="100" align="center">
              <template slot-scope="scope">
                <div style="color: #069BBC;cursor: pointer;" @click="handleCommand(scope.row)">查看</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip>
            </el-table-column> -->
            <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="specs" label="商品规格" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip min-width="120" align="center">
            </el-table-column>
            <el-table-column prop="brand" label="商品品牌" show-overflow-tooltip min-width="120" align="center">
            </el-table-column>
            <el-table-column prop="productPrice" label="售价(元)" show-overflow-tooltip min-width="110" align="center">
            </el-table-column>
            <el-table-column prop="productCount" label="购买数量" show-overflow-tooltip min-width="100" align="center"
              fixed="right">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom">
        <div>商品总价：￥{{ Number(basicInfo.productTotal).toFixed(2) }}</div>
        <div>打包费：￥{{ Number(basicInfo.packAmount).toFixed(2) }}</div>
        <div>优惠：￥{{ Number(basicInfo.discountAmount).toFixed(2) }}</div>
        <div>应付：<span style="color: red;font-weight: bolder;font-size: 20px;">
            ￥{{ Number(basicInfo.payAmount).toFixed(2) }}
          </span>
        </div>
      </div>
    </div>
    <!-- 图片 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[url]" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';// 导入大图预览组件
export default {
  components: {
    ElImageViewer
  },
  data() {
    return {
      loading: true,
      basicInfo: {},//基本信息
      tableData: [],//表格数据
      orderId: '',//订单id
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      showViewer: false, // 显示查看器
    }
  },

  created() {
    this.orderId = this.$route.query.orderId//订单id
    // console.log(this.orderId);
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.ordersDet, {
        params: {
          orderId: this.orderId,//订单id
          shopId: this.$store.state.userInfo.shopId,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.basicInfo = result
          this.tableData = result.selectOnlineOrdersProductVoList
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })
    },
    // 订单子状态
    getOrderFormStatusText(status) {
      switch (String(status)) {
        case '0':
          return '待付款';
        case '1':
          return '待接单';
        case '2':
          return '备货中';
        case '3':
          return '骑手配送中';
        case '4':
          return '已完成';
        case '5':
          return '待自提';
        case '6':
          return '售后中';
        case '7':
          return '待退款';
        case '8':
          return '已退款';
        default:
          return '已取消';
      }
    },
    // 订单状态
    getStatusText(status) {
      switch (String(status)) {
        case '0':
          return '待付款';
        case '1':
          return '处理中';
        case '4':
          return '已完成';
        default:
          return '已取消';
      }
    },
    getCouponInfo(num) {
      if (num == 1) {
        return '满减券'
      } else if (num == 2) {
        return '折扣'
      } else {
        return '代金券'
      }
    },
    // 点击按钮预览图片
    handleCommand(row) {
      this.url = row.picUrl;
      this.showViewer = true
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
  },
}
</script>

<style scoped lang='scss'>
.det {
  padding: 0 30px;

  .detail {
    padding-top: 20px;
    border-bottom: 1px solid rgba(3, 16, 14, 0.06);

    .title {
      font-size: 16px;
      margin-bottom: 25px;
    }

    .row {
      color: #707070;
      // font-size: 16px;

      .col {
        margin-bottom: 20px;
        // margin-right: 10px;
        font-size: 16px;
      }

      .order-code {
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }


  }

  .bottom {
    display: flex;
    align-items: flex-end;
    color: #505554;
    justify-content: flex-end;
    padding: 10px 0;

    div {
      margin-right: 20px;
    }
  }

}
</style>